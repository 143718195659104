import styled from "styled-components";
import * as palette from "../../styles/variables";

export const BannerSection = styled.section`
  background: ${palette.APP_BACKGROUND_COLOR};
  padding: 80px 60px 50px;
  height: calc(100vh - 105px);
  @media only screen and (max-width: 768px) {
    padding: 20px;
    height: auto;
    .mobMedialWrapper {
      position: unset;
      margin-top: 8px;
      padding: 0px;
      .mobMedialLinks {
        a {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    padding: 60px 48px 50px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    padding: 60px 48px 50px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }

  .banner-container {
    display: flex;
    justify-content: space-between;
    max-width: 1300px;
    margin: auto;
    position: relative;
    z-index: 1;
    @media only screen and (min-width: 1500px) {
      max-width: 1400px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      z-index: unset;
      .content {
        z-index: 1;
      }
      .ant-col {
        min-width: 100%;
      }
    }
    .banner-image-details {
      z-index: 0;
      .bannerbg {
        z-index: -1;
        position: absolute;
        top: -23%;
      }
      @media (max-width: 768px) {
        display: flex;
      }
    }
    .onboard-animations {
      position: absolute;
      top: 50px;
      right: 0px;
    }
  }

  .banner-content {
    .ant-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      border-radius: 6px;
      color: ${palette.WHITE_COLOR} !important;
      .btnCont {
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 32px;
        img {
          width: 1.8vw;
          height: 1.8vw;
          margin-left: 0.8vw;
        }
      }
      .arrow {
        margin-left: 0vw;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.6vw;
      }
    }
    @media only screen and (max-width: 768px) {
      padding-left: 0;
      max-width: 500px;
      margin: 0 auto;
      .btnCont img {
        width: 14px !important;
        height: 14px !important;
        margin-left: 6px !important;
      }
    }
    @media only screen and (min-width: 1300px) {
      margin-top: 8%;
    }
  }

  .content-title {
    margin: 8px 0px 4px;
    font-weight: 700;
    color: ${palette.WHITE_COLOR};
    font-family: ${palette.FONT_FAMILY};
    span {
      display: block;
      background: linear-gradient(163.32deg, #A8B7D3 9.11%, #B5C6E2 34.93%, #D3E0EF 57.41%, #E5EFFA 93.73%);
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
      background-clip: text !important;
      text-fill-color: transparent !important;
    }
    .topText {
      margin-bottom: 6px;
      display: inline-block;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      font-size: 30px;
      line-height: 36px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 28px;
      line-height: 34px;
      margin: 0px 0px 8px !important;
      text-align: center;
    }
    @media only screen and (max-width: 380px) {
      font-size: 7.2vw;
      line-height: 8.5vw;
    }
  }

  .content-sub-title {
    font-weight: 600;
    font-size: 2.7vw;
    line-height: 3.2vw;
    font-family: ${palette.FONT_FAMILY};
    color: ${palette.BLACK_COLOR};
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      font-size: 36px;
      line-height: 42px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 26px;
      line-height: 34px;
      margin: 0px 0px 8px !important;
      text-align: center;
    }
  }

  .content-description {
    font-weight: normal;
    color: ${palette.STEEL_MEDIUM_LIGHT};
    margin: 20px 0px 28px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
      margin: 20px 0px 28px;
      text-align: center;
    }
  }

  .banner-appPage {
    padding-left: 25px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 15px;
      margin-top: 0;
      padding-left: 0;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .section-background {
    position: absolute;
    right: 0;
    top: -2%;
    z-index: -1;
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      right: -2%;
      top: -2%;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      top: -3%;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
    @media only screen and (min-width: 1500px) {
      top: 0;
    }
  }

  .circle {
    width: 10rem;
    height: 5rem;
    opacity: 50%;
    border-radius: 24.3513px;
    position: fixed;
    bottom: 0;
    filter: blur(65px);
    background: #00E8EC;
    top: 12%;
    left: 0;
    z-index: 23;
  }
  .circleBlue {
    width: 10rem;
    height: 7rem;
    opacity: 50%;
    border-radius: 24.3513px;
    position: fixed;
    bottom: 0px;
    filter: blur(90px);
    background: rgb(29, 0, 143);
    top: 20%;
    left: 14%;
  }

  .mediawrapper {
    z-index: 1;
    margin: 0;
    top: 32%;
    transform: translateY(-50%);
    bottom: unset;
    padding: 16px 8px 16px 8px;
    border-radius: 8px 0px 0px 8px;
    background: ${palette.BLACK_COLOR};
    box-shadow: 0px 0px 16px 0px #00C7B729;
    .medialinks {
      flex-direction: column;
    }
  }
`;

export const CountDownText = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 1.35vw;
  font-weight: 400;
  letter-spacing: 0.02em;
  font-family: ${palette.CONTENT_FONT_FAMILY};
  color: ${palette.TEXT_COLOR};
  img {
    width: 24px;
    height: 24px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }
`;

export const VideoContainer = styled.div`
  z-index: -1;
  overflow: hidden;
  position: relative;
  top: -16%;
  transform: scale(1.2);
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(10, 23, 29, 0.00) 0%, rgba(10, 23, 29, 0.00) 0.52%, #0A171D 100%);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  video::-webkit-media-controls {
    opacity: 0;
  }
  video::-webkit-media-controls {
    display: none !important;
  }
  @media only screen and (max-width: 768px) {
    margin: 0px;
    transform: none;
    margin-left: -20px;
    margin-right: -20px;
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    transform: none;
  }
`;

export const CountdownContainer = styled.div`
  margin-top: 20px;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CountdownWrapper = styled.ul`
  display: flex;
  gap: 14px;
  margin: 14px 0px;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    .time {
      position: relative;
      display: inline-flex;
      .number {
        position: absolute;
        top: 56%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        .timeLeft {
          font-family: Orloj;
          font-size: 2.3vw;
          font-weight: 400;
          position: relative;
          z-index: 100;
          background: linear-gradient(135deg, #00C7B7 44.18%, #088CE0 100%);
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important;
          background-clip: text !important;
          text-fill-color: transparent !important;
        }
        .placeholder {
          font-family: Orloj;
          font-size: 2.3vw;
          font-weight: 400;
          position: absolute;
          top: 0;
          z-index: 50;
          color: #29313B;
        }
      }
      img {
        width: 3.8vw;
        height: 4vw;
      }
    }
    .text {
      font-family: ${palette.CONTENT_FONT_FAMILY};
      font-size: 1.35vw;
      font-weight: 400;
      letter-spacing: 0.02em;
      text-align: center;
      color: ${palette.PRIMARY_COLOR};
    }
  }
  @media only screen and (max-width: 768px) {
    li {
      .time {
        .number {
          .timeLeft, .placeholder {
            font-size: 34px;
          }
        }
        img {
          width: 54px;
          height: 60px;
        }
      }
      .text {
        font-size: 18px;
      }
    }
  }
`;

export const WaitListForm = styled.div`
  margin: 20px 0px;
  background: linear-gradient(180deg, rgba(0, 187, 255, 0.168) 0%, rgba(196, 77, 255, 0.168) 100%);
  border-radius: 16px;
  box-shadow: 0px 20px 40px 0px #00000026;
  padding: 6px;
  max-width: 80%;
  .ant-input {
    border: none !important;
    box-shadow: none !important;
    font-size: 1.2vw;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  .formWrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    padding: 6px;
    border: 1.5px solid #008080;
    background: #182D42;
  }
  .emailInput {
    margin: 0;
    flex: 2;
  }
  .buttonInput {
    margin: 0;
    .ant-btn {
      height: 3vw !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      border-radius: 6px;
      color: ${palette.WHITE_COLOR} !important;
      font-size: 1.1vw;
      line-height: 1.5vw;
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    background: none;
    box-shadow: none;
    border-radius: 0px;
    max-width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
    .bannerForm {
      padding-bottom: 0px;
    }
    .formWrapper {
      flex-direction: column;
      border: none;
      background: none;
      gap: 18px;
    }
    .emailInput {
      width: 100%;
    }
    .buttonInput {
      width: 100%;
      .ant-btn {
        width: 100%;
        height: 48px !important;
        font-size: 20px !important;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    .ant-input {
      border: 1.5px solid rgb(0, 128, 128) !important;
      border-radius: 8px;
      height: 48px;
      font-size: 18px;
    }
  }
`;

export const MediaWrapper = styled.div`
  margin-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0px;
  z-index: 1;
`;

export const MediaLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2vw;
    height: 2.2vw;
  }
`;

export const IntouchTerms = styled.div`
  margin-top: 14px;
  a {
    font-size: 1vw;
    line-height: 1vw;
    color: ${palette.PRIMARY_COLOR};
    font-family: ${palette.CONTENT_FONT_FAMILY};
    padding: 0px 12px;
    border-right: 1px solid ${palette.PRIMARY_COLOR};
    display: inline-block;
    &:last-child {
      border: none;
    }
    &:first-child {
      padding-left: 0px;
    }
    &:hover, &:focus, &:active {
      background: linear-gradient(135deg, #00C7B7 44.18%, #088CE0 100%);
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
      background-clip: text !important;
      text-fill-color: transparent !important;
    }
    @media only screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const AppLinks = styled.div`
  margin-top: 40px;
  h4 {
    font-family: ${palette.CONTENT_FONT_FAMILY};
    font-size: 1.8vw;
    font-weight: 400;
    line-height: 2.4vw;
    color: ${palette.PRIMARY_COLOR};
  }
  .btnGroup {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 16px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 4vw;
        width: 12vw;
      }
      .androidIcon {
        width: 13vw;
      }
    }
  }
  @media (max-width: 768px) {
    margin: 20px 0px 30px;
    h4 {
      text-align: center;
      font-size: 17px;
      line-height: 22px;
    }
    .btnGroup {
      justify-content: center;
      a img {
        width: 133px;
        height: 44px;
      }
      a .androidIcon {
        width: 148px;
      }
    }
  }
`;

export const BannerFooter = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  right: 60px;
  margin-top: 40px;
  padding-bottom: 45px;
  flex-direction: row;
  left: 60px;
  gap: 40px;
  .waitlistform {
    flex: 2;
    max-width: 100%;
    margin: 0px;
    background: none;
    border-radius: 0px;
    box-shadow: none;
    padding: 0px;
  }
  @media only screen and (max-width: 768px) {
    position: unset;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;

export const FeaturedIn = styled.div`
  flex: 3;
  h5 {
    font-family: ${palette.CONTENT_FONT_FAMILY};
    font-size: 1.4vw;
    font-weight: 400;
    line-height: 2vw;
    color: ${palette.PRIMARY_COLOR};
  }
  .listItems {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 28px;
  }
  @media only screen and (max-width: 768px) {
    h5 {
      text-align: center;
      font-size: 16px;
      line-height: 22px;
    }
    .listItems {
      margin-top: 16px;
      gap: 20px;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      a {
        width: 26%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
