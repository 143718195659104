import React, { useState, useEffect } from "react"
import {
  SectionContainer,
  SectionHeader,
  CarouselContainer,
  CarouselButton,
  ImageWrapper,
  Dot,
  ResponsiveDiv,
} from "./styles"
import { StaticImage } from "gatsby-plugin-image"

export const CustomerFeedback = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [windowWidth, setWindowWidth] = useState(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth)

      const handleResize = () => {
        setWindowWidth(window.innerWidth)
      }

      window.addEventListener("resize", handleResize)

      // Cleanup the event listener on unmount
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [])

  const images = [
    {
      Component: (
        <StaticImage
          src="../../images/RaghuFeedback.png"
          alt="Raghu Saranathan Feedback"
          placeholder="none"
        />
      ),
      alt: "Raghu Saranathan Feedback",
    },
    {
      Component: (
        <StaticImage
          src="../../images/IdeenFeedback.png"
          alt="Ideen Feedback"
          placeholder="none"
        />
      ),
      alt: "Ideen Feedback",
    },
    {
      Component: (
        <StaticImage
          src="../../images/PranavFeedback.png"
          alt="Pranav Feedback"
          placeholder="none"
        />
      ),
      alt: "Pranav Feedback",
    },
  ]

  const handlePrevious = () => {
    const decrementValue = windowWidth <= 768 ? 1 : 2
    setCurrentIndex(
      prevIndex => (prevIndex - decrementValue + images.length) % images.length
    )
  }

  const handleNext = () => {
    const incrementValue = windowWidth <= 768 ? 1 : 2
    setCurrentIndex(prevIndex => (prevIndex + incrementValue) % images.length)
  }

  return (
    <SectionContainer>
      <SectionHeader>
        <h2>See what people are saying</h2>
      </SectionHeader>
      <CarouselContainer>
        <CarouselButton onClick={handlePrevious}>
          <StaticImage
            src="../../images/Back-Arrow.png"
            alt="Previous"
            layout="fixed"
            width={40}
            height={40}
          />
        </CarouselButton>
        <ImageWrapper>
          <a>{images[currentIndex].Component}</a>
          <a>{images[(currentIndex + 1) % images.length].Component}</a>
        </ImageWrapper>
        <CarouselButton onClick={handleNext}>
          <StaticImage
            src="../../images/ForwordArrow.png"
            alt="Next"
            layout="fixed"
            width={40}
            height={40}
          />
        </CarouselButton>
      </CarouselContainer>
      <ResponsiveDiv>
        {images.map((_, index) => (
          <Dot
            key={index}
            className={
              (windowWidth <= 768 && currentIndex === index) ||
              (windowWidth > 768 &&
                (currentIndex === index || currentIndex + 1 === index))
                ? "active"
                : ""
            }
            onClick={() => {
              if (windowWidth <= 768) {
                setCurrentIndex(index)
              } else {
                setCurrentIndex(index % 2 === 0 ? index : index - 1)
              }
            }}
          />
        ))}
      </ResponsiveDiv>
    </SectionContainer>
  )
}

export default CustomerFeedback
