import styled from "styled-components"
import * as palette from "../../styles/variables"

export const SectionContainer = styled.section`
  padding: 100px 60px;
  background: ${palette.SECTION_BACKGROUND_COLOR};
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    padding: 30px 20px;
  }
`
export const SectionHeader = styled.div`
  max-width: 55vw;
  margin: 0 auto;
  h2 {
    text-align: center;
    background: linear-gradient(
      163.32deg,
      #a8b7d3 9.11%,
      #b5c6e2 34.93%,
      #d3e0ef 57.41%,
      #e5effa 93.73%
    );
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    text-fill-color: transparent !important;
  }
  @media screen and (min-width: 1200px) {
    h2 {
      padding-bottom: 100px;
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    h2 {
      font-size: 18px;
      padding-bottom: 50px;
    }
  }
`
export const CarouselContainer = styled.div`
  display: flex;
  justify-content: center; // Horizontally center
  align-items: center; // Vertically center
  // height: 100vh;
`

export const CarouselButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  transform: scale(1); // Default scale

  @media (max-width: 768px) {
    transform: scale(0.7); // Reduce size by 30% for small screens
  }
`

export const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;

  a {
    width: 450px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    a:nth-child(2) {
      /* Hide the second image on smaller screens */
      display: none;
    }
    a {
      width: 280px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
export const Dot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 5px;
  background-color: #ccc;
  border-radius: 50%; // This makes it a circle
  cursor: pointer;

  &.active {
    background: linear-gradient(90deg, #00c7b7, #1a3e83);
    border-radius: 16px; // This makes it a rectangle
    width: 40px; // Adjust the width as you desire
    height: 8px; // Adjust the height if needed
  }
`
export const ResponsiveDiv = styled.div`
  text-align: center;
  margin-top: 20px;
  padding-top: 50px;

  @media (max-width: 768px) {
    padding-top: 40px;
  }
`
