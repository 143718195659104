import React from "react"
import { ContentSection, ImageSec, SectionContainer } from "./styles"
import SectionImage from "../../images/futureisyou.webp"
import { Col, Row } from "antd"
import * as palette from "../../styles/variables";
import { StaticImage } from "gatsby-plugin-image";

export const FutureSection = () => {
  return (
    <SectionContainer style={{ background: palette.SECTION_BACKGROUND_COLOR }}>
      {/* <div className="circleLight" />
      <div className="circleBlue" /> */}
      <Row className="workRow" style={{ alignItems: "center", flexDirection: "row-reverse" }}>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <ImageSec>
            <span>
              <StaticImage src="../../images/futureisyou.webp" alt="You Are Our Mission" placeholder="none" />
              {/* <img src={SectionImage} alt="You Are Our Mission" /> */}
            </span>
          </ImageSec>
        </Col>
        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
          <ContentSection>
            <h3>You Are Our Mission</h3>
            <p className="description">
              Fit Hub is an adaptive technology that is able to flow with your every need.
            </p>
          </ContentSection>
        </Col>
      </Row>
    </SectionContainer>
  )
}