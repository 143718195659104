import React from "react"
import { ContentSection, ImageSec, SectionContainer } from "./styles"
import SectionImage from "../../images/personalised.webp"
import { Col, Row } from "antd"
import { StaticImage } from "gatsby-plugin-image"

export const PersonalizedSection = () => {
  return(
    <SectionContainer>
      <Row className="workRow" style={{alignItems: "center"}}>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <ImageSec>
            <span>
              <StaticImage src="../../images/personalised.webp" alt="Hyper Personalized" placeholder="none" />
              {/* <img src={SectionImage} alt="Hyper Personalized" /> */}
            </span>
          </ImageSec>
        </Col>
        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
          <ContentSection>
            <h3>Hyper Personalized</h3>
            <p className="description">
              Our generative AI technology continuously learns & evolves from your every interaction.
            </p>
          </ContentSection>
        </Col>
      </Row>
      {/* <div className="circle" /> */}
    </SectionContainer>
  )
}