import styled from "styled-components";
import * as palette from "../../styles/variables";

export const SectionContainer = styled.section`
  padding: 60px;
  background: ${palette.SECTION_BACKGROUND_COLOR};
  height: 18vw;
  @media only screen and (max-width: 768px) {
    padding: 0px;
    height: auto;
    overflow: hidden;
    .waitlist {
      padding: 40px 20px;
      .emailInput {
        padding: 8px;
        border-radius: 16px;
        background: linear-gradient(180deg, rgba(0, 187, 255, 0.168) 0%, rgba(196, 77, 255, 0.168) 100%);
        box-shadow: 0px 20px 40px 0px #00000026;
        .ant-input {
          background: #182D42 !important;
          height: 54px !important;
        }
      }
      .buttonInput .ant-btn {
        width: 75%;
        margin: 0 auto;
      }
    }
  }
  .circle {
    width: 10rem;
    height: 10rem;
    opacity: 0.5;
    border-radius: 24.3513px;
    position: absolute;
    bottom: 0px;
    filter: blur(90px);
    background: rgb(0, 232, 236);
    top: -30%;
    left: 48%;
    z-index: 1;
  }
  .circleBlue {
    width: 10rem;
    height: 7rem;
    opacity: 50%;
    border-radius: 24.3513px;
    position: absolute;
    bottom: 0px;
    filter: blur(90px);
    background: rgb(29, 0, 143);
    top: 12%;
    left: 65%;
  }
`;

export const JourneyCard = styled.div`
  margin-top: 0% !important;
  max-width: 1070px;
  margin: 30px auto;
  background: linear-gradient(135deg, #00978A 0%, #07AFAE 51.04%, #38A9C1 100%);
  border-radius: 16px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  h3 {
    color: ${palette.WHITE_COLOR};
    font-weight: 700;
    text-align: center;
  }
  p {
    max-width: 330px;
    margin: 12px auto 20px;
    color: ${palette.WHITE_COLOR};
    text-align: center;
  }
  .compWrapper {
    width: 60%;
    .formWrapper {
      background: ${palette.WHITE_COLOR} !important;
    }
    .ant-input {
      color: #364254 !important;
    }
    .ant-input::placeholder {
      color: #364254 !important;
      opacity: 0.6;
    }
  }
  @media only screen and (max-width: 768px) {
    border-radius: 0px;
    margin: 0% !important;
    padding: 40px 20px;
    .compWrapper {
      width: 100%;
      .formWrapper {
        background: transparent !important;
        .ant-input {
          border-color: ${palette.WHITE_COLOR} !important;
          background: #17ADB4 !important;
          color: ${palette.WHITE_COLOR} !important;
        }
        .ant-input::placeholder {
          color: ${palette.WHITE_COLOR} !important;
          opacity: 0.6;
        }
        .buttonInput {
          button {
            background: ${palette.WHITE_COLOR} !important;
            color: ${palette.ACCENT01} !important;
          }
        }
      }
    }
    .btnGroup {
      justify-content: center;
      a img {
        width: 133px !important;
        height: 44px !important;
      }
      a .androidIcon {
        width: 148px !important;
      }
    }
  }
  .btnGroup {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 8px;
    margin-bottom: 12px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 4vw;
        width: 12vw;
      }
      .androidIcon {
        width: 13vw;
      }
    }
`;
