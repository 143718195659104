import React from "react"
import { AccessiblitySection } from "./accessiblity"
import { FutureSection } from "./future"
import { PersonalizedSection } from "./personalized"
import { SectionContainer, SectionHeader } from "./styles"

export const WhatWeDo = () => {
  return(
    <div>
      <SectionContainer id="what_is_fithub" className="secTitle">
        <SectionHeader>
          <h2>What is Fit Hub?</h2>
          <p>Embark on a transformative fitness journey with FitHub AI, the app powered by advanced large language models and personalized coaching.</p>
        </SectionHeader>
      </SectionContainer>
      <PersonalizedSection />
      <FutureSection />
      <AccessiblitySection />
    </div>
  )
}