import React from "react"
import { ContentSection, ImageSec, SectionContainer } from "./styles"
import SectionImage from "../../images/accessiblity.webp"
import { Col, Row } from "antd"
import { StaticImage } from "gatsby-plugin-image"

export const AccessiblitySection = () => {
  return(
    <SectionContainer>
      <Row className="workRow" style={{alignItems: "center"}}>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <ImageSec>
            <span>
              <StaticImage src="../../images/accessiblity.webp" alt="AI within Reach" placeholder="none" />
              {/* <img src={SectionImage} alt="AI within Reach" /> */}
            </span>
          </ImageSec>
        </Col>
        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
          <ContentSection>
            <h3>AI within Reach</h3>
            <p className="description">
              With user-friendly features built on a conversational interface Fit Hub is always a tap away.
            </p>
          </ContentSection>
        </Col>
      </Row>
      {/* <div className="circleLight" style={{bottom: "15%", top: "unset", left: "6%"}} />
      <div className="circleBlue" style={{bottom: 0, top: "unset"}} /> */}
    </SectionContainer>
  )
}