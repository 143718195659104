import { Button, Form, Input, Modal } from "antd"
import React, { useState } from "react"
import { WaitListForm } from "../Banner/styles"
import WaitlistIcon from "../../images/sparkle.webp"
import { JourneyCard, SectionContainer } from "./styles"
import EmailConfirm from "../EmailConfirm"
import useIsMobile from "../../utils/resize"
import WaitlistIconMobile from "../../images/waitlistMobile.webp"
import { StaticImage } from "gatsby-plugin-image"
import IosIcon from "../../images/Badge-iOS.webp"
import AndroidIcon from "../../images/Badge-Android.webp"

const Journey = () => {

  const [form] = Form.useForm();

  const screenSize = useIsMobile();

  const [openModal, setOpenModal] = useState(false);

  const [disabled, setDisabled] = useState(false)

  const onFinish = async (values) => {
    setDisabled(true);
    setOpenModal(true);
    setDisabled(false);
    form.resetFields();
    window?.dataLayer?.push({
      event: 'waitlist_form_submit',
      email: values.email,
      data: {
        url: window.location.href,
        title: document.title,
      },
    });
    await fetch('https://api-main.aifithub.com/email/communitylist', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: values.email,
        domain: window.location.hostname
      }),
    }).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
      setDisabled(false);
    });
    // const sendEmailFn = httpsCallable (getFunctions(), "sendWaitlistEmail")
    // setOpenModal(true)
    // setDisabled(false);
    // form.resetFields();
    // sendEmailFn(sendData).then((result) => {
    // })
    // .catch((error) => {
    //   setDisabled(false);
    // })
  };

  const handleCancel = () => {
    setOpenModal(false)
  }

  return (
    <SectionContainer id="journeyCard">
      {/* <div className="circle" />
      <div className="circleBlue" /> */}
      <JourneyCard>
        <h3>Start your new fitness journey</h3>
        <p>Fit Hub is available to download on the App Store & Playstore. Get it Now</p>
        <div className="btnGroup">
          <a href="https://apps.apple.com/us/app/fit-hub-evolve/id6450487742" target="_blank" without="true" rel="noopener noreferrer">
            <img src={IosIcon} alt="IosIcon" />
            {/* <StaticImage src="../../images/Badge-iOS.webp" alt="IosIcon" placeholder="none" /> */}
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.Spyn.FitHub" target="_blank" without="true" rel="noopener noreferrer">
            <img src={AndroidIcon} alt="AndroidIcon" className="androidIcon" />
            {/* <StaticImage src="../../images/Badge-Android.webp" alt="AndroidIcon" placeholder="none" /> */}
          </a>
        </div>
        {
          !screenSize &&
          <WaitListForm className="compWrapper">
            <Form
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              className="formWrapper"
              form={form}
              id="waitlist_form"
            >
              <Form.Item
                label=""
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "",
                  },
                ]}
                className="emailInput"
              >
                <Input placeholder="Your email address" />
              </Form.Item>
              <Form.Item className="buttonInput">
                <Button type="primary" htmlType="submit"
                  icon={
                    screenSize ? <StaticImage src="../../images/waitlistMobile.webp" alt="waitlist" placeholder="none" /> :
                      <StaticImage src="../../images/sparkle.webp" alt="waitlist" placeholder="none" />
                    // <img src={screenSize ? WaitlistIconMobile : WaitlistIcon} alt="waitlist" />
                  }
                  disabled={disabled}
                  id="waitlist_button"
                >
                  Get Exclusive Updates
                </Button>
              </Form.Item>
            </Form>
          </WaitListForm>
        }
      </JourneyCard>
      {
        screenSize &&
        <WaitListForm className="waitlist">
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            className="formWrapper"
            form={form}
            id="waitlist_form"
          >
            <Form.Item
              label=""
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "",
                },
              ]}
              className="emailInput"
            >
              <Input placeholder="Your email address" />
            </Form.Item>
            <Form.Item className="buttonInput">
              <Button type="primary" htmlType="submit"
                icon={<StaticImage src="../../images/sparkle.webp" alt="waitlist" placeholder="none" />
                  // <img src={screenSize ? WaitlistIconMobile : WaitlistIcon} alt="waitlist" />
                }
                disabled={disabled}
                id="waitlist_button"
              >
                Get Exclusive Updates
              </Button>
            </Form.Item>
          </Form>
        </WaitListForm>
      }
      <Modal title="" visible={openModal} onCancel={handleCancel} footer="" className="getappModal" width={screenSize ? '90vw' : '50vw'} centered maskClosable={false}>
        <EmailConfirm title="Welcome to the Fit Hub Family!" message="" from="exclusiveUpdate" handleCancel={handleCancel} />
      </Modal>
    </SectionContainer>
  )
}

export default Journey
