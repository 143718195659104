import styled from "styled-components";
import * as palette from "../../styles/variables";

export const SectionContainer = styled.section`
  padding: 100px 60px;
  background: ${palette.SECTION_BACKGROUND_COLOR};
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    padding: 60px 20px;
  }
`;

export const MetricsList = styled.div`
  max-width: 1300px;
  margin: 0px auto;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 32px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }
`;

export const MetricsListItem = styled.div`
  border-radius: 16px;
  padding: 20px 20px 26px;
  position: relative;
  z-index: 2;
  .imageWrapper {
    width: 6vw;
    height: 6vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  border-radius: 16px;
  border: 1px solid transparent;
  background-image: linear-gradient(rgb(10, 23, 29, 0), rgb(10, 23, 29, 0)), linear-gradient(135deg, rgba(0, 199, 183, 0.6) -1.12%, rgba(0, 199, 183, 0.1) 104.74%) !important;
  border-image-source: linear-gradient(151.62deg, rgba(0, 199, 183, 0.6) -1.12%, rgba(0, 199, 183, 0.1) 104.74%);
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  box-shadow: 2px 1000vh 1px #0A171D inset;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 2.1vw;
    font-weight: 600;
    line-height: 2.8vw;
    text-align: center;
    background: linear-gradient(135deg, #00C7B7 44.18%, #088CE0 100%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    text-fill-color: transparent !important;
    margin: 12px 0px 8px;
  }
  h4 {
    font-family: ${palette.CONTENT_FONT_FAMILY};
    font-size: 1.3vw;
    font-weight: 400;
    line-height: 1.7vw;
    color: ${palette.PRIMARY_COLOR};
    text-align: center;
  }
  @media (max-width: 768px) {
    padding: 20px;
    height: auto;
    width: 45%;
    .imageWrapper {
      width: 72px;
      height: 72px;
    }
    h3 {
      font-size: 20px;
      line-height: 25px;
    }
    h4 {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;
