import React, { useEffect, useState } from "react"
import { Button, Row, Col, Form, Input, Modal } from "antd"
import { BannerSection, VideoContainer, CountDownText, CountdownWrapper, WaitListForm, MediaLinks, IntouchTerms, MediaWrapper, CountdownContainer, AppLinks, BannerFooter, FeaturedIn } from "./styles"
import VideoImg from "../../images/videoPlaceholder.webp"
import useIsMobile from "../../utils/resize"
import CountdownIcon from "../../images/countdown.webp"
import TimerBgIcon from "../../images/timerBg.webp"
import FithubVideo from "../../videos/FithubVideo.mp4"
import { calculateTimeLeft } from "./useCountdown";
import WaitlistIcon from "../../images/sparkle.webp"
import Instagram from "../../images/instagram.webp"
import Facebook from "../../images/facebook.webp"
import Twitter from "../../images/twitter.webp"
import EmailConfirm from "../EmailConfirm"
import { SmileOutlined } from '@ant-design/icons';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import IosIcon from "../../images/Badge-iOS.webp"
import AndroidIcon from "../../images/Badge-Android.webp"

const Banner = () => {

  const [form] = Form.useForm();

  const screenSize = useIsMobile();

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const [openModal, setOpenModal] = useState(false);

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    var video = document.getElementById("myVideo");
    if (video) {
      function playVideo() {
        video.play();
      }
      // Check if autoplay is supported
      var autoplaySupported = false;
      if (typeof video.autoplay === "boolean") {
        autoplaySupported = video.autoplay;
      } else {
        autoplaySupported = video.autoplay === "string";
      }
      // If autoplay is not supported, add the autoplay attribute dynamically
      if (!autoplaySupported) {
        video.setAttribute("autoplay", "");
        playVideo();
      }
    }
  }, [])

  const onFinish = async (values) => {
    setDisabled(true);
    setOpenModal(true);
    setDisabled(false);
    form.resetFields();
    window?.dataLayer?.push({
      event: 'waitlist_form_submit',
      email: values.email,
      data: {
        url: window.location.href,
        title: document.title,
      },
    });
    await fetch('https://api-main.aifithub.com/email/communitylist', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: values.email,
        domain: window.location.hostname
      }),
    }).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
      setDisabled(false);
    });
  };

  const handleCancel = () => {
    setOpenModal(false)
  }

  return (
    <BannerSection id="homeBanner">
      <Row className="banner-container" type="flex">
        <Col xs={24} sm={24} md={11} lg={11} xl={11} className="content">
          <div className="banner-content">
            <h2 className="content-title">
              <span className="topText">Evolve your Fitness</span>
              {/* <span>With Generative AI</span> */}
            </h2>
            <AppLinks>
              <h4>Get Started Today</h4>
              <div className="btnGroup">
                <a href="https://apps.apple.com/us/app/fit-hub-evolve/id6450487742" target="_blank" without="true" rel="noopener noreferrer">
                  <img src={IosIcon} alt="IosIcon" />
                  {/* <StaticImage src="../../images/Badge-iOS.webp" alt="IosIcon" placeholder="none" /> */}
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.Spyn.FitHub" target="_blank" without="true" rel="noopener noreferrer">
                  <img src={AndroidIcon} alt="AndroidIcon" className="androidIcon" />
                  {/* <StaticImage src="../../images/Badge-Android.webp" alt="AndroidIcon" placeholder="none" /> */}
                </a>
              </div>
            </AppLinks>
            {/* {
              timeLeft &&
              <CountdownContainer>
                <CountDownText>
                  <StaticImage src="../../images/countdown.webp" alt="CountdownIcon" placeholder="none" />
                  Launching in
                </CountDownText>
                <CountdownWrapper>
                  <li>
                    <div className="time">
                      <StaticImage src="../../images/timerBg.webp" alt="TimerBgIcon" placeholder="none" />
                      <span className="number">
                        <span className="timeLeft">{timeLeft?.days}</span>
                        <span className="placeholder">88</span>
                      </span>
                    </div>
                    <span className="text">days</span>
                  </li>
                  <li>
                    <div className="time">
                      <StaticImage src="../../images/timerBg.webp" alt="TimerBgIcon" placeholder="none" />
                      <span className="number">
                        <span className="timeLeft">{timeLeft?.hours}</span>
                        <span className="placeholder">88</span>
                      </span>
                    </div>
                    <span className="text">hrs</span>
                  </li>
                  <li>
                    <div className="time">
                      <StaticImage src="../../images/timerBg.webp" alt="TimerBgIcon" placeholder="none" />
                      <span className="number">
                        <span className="timeLeft">{timeLeft?.minutes}</span>
                        <span className="placeholder">88</span>
                      </span>
                    </div>
                    <span className="text">mins</span>
                  </li>
                  <li>
                    <div className="time">
                      <StaticImage src="../../images/timerBg.webp" alt="TimerBgIcon" placeholder="none" />
                      <span className="number">
                        <span className="timeLeft">{timeLeft?.seconds}</span>
                        <span className="placeholder">88</span>
                      </span>
                    </div>
                    <span className="text">secs</span>
                  </li>
                </CountdownWrapper>
              </CountdownContainer>
            } */}
          </div>
        </Col>
        <Col xs={24} sm={24} md={13} lg={13} xl={13} className="banner-image-details">
          <div style={{ height: "100%" }}>
            <VideoContainer>
              <video loop muted playsInline poster={VideoImg} controls={false} id="myVideo">
                <source src={FithubVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </VideoContainer>
          </div>
        </Col>
      </Row>
      <BannerFooter>
        <FeaturedIn>
          <h5>Featured in</h5>
          <div className="listItems">
            <a href="https://www.benzinga.com/pressreleases/23/07/g33482506/spyn-inc-announces-the-launch-of-a-hyper-" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/Benzinga.webp" alt="Benzinga" placeholder="none" />
            </a>
            <a href="https://www.bloomberg.com/press-releases/2023-07-31/spyn-inc-announces-the-launch-of-a-hyper-personalized-generative-ai-powered-wellness-application" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/Bloomberg.webp" alt="Bloomberg" placeholder="none" />
            </a>
            <a href="https://finance.yahoo.com/news/spyn-inc-announces-launch-hyper-231200774.html" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/YahooFinance.webp" alt="YahooFinance" placeholder="none" />
            </a>
            <a href="https://www.marketwatch.com/press-release/spyn-inc-announces-the-launch-of-a-hyper-personalized-generative-ai-powered-wellness-application-872de418?mod=search_headline" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/MarketWatch.webp" alt="MarketWatch" placeholder="none" />
            </a>
            <a href="https://markets.businessinsider.com/news/stocks/spyn-inc.-announces-the-launch-of-a-hyper-personalized-generative-ai-powered-wellness-application-1032502452" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/Insider.webp" alt="Insider" placeholder="none" />
            </a>
            <a href="https://www.geekwire.com/2023/amazon-and-microsoft-vet-launches-fitness-and-meditation-chatbot-powered-by-gpt/" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/GeekWire.webp" alt="GeekWire" placeholder="none" />
            </a>
          </div>
        </FeaturedIn>
        {
          !screenSize &&
          <WaitListForm className="waitlistform">
            <Form
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              className="formWrapper bannerForm"
              form={form}
              id="waitlist_form"
            >
              <Form.Item
                label=""
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "",
                  },
                ]}
                className="emailInput"
              >
                <Input placeholder="Your email address" />
              </Form.Item>
              <Form.Item className="buttonInput">
                <Button type="primary" htmlType="submit"
                  icon={
                    <StaticImage src="../../images/sparkle.webp" alt="WaitlistIcon" placeholder="none" />
                    // <img src={WaitlistIcon} alt="waitlist" />
                  }
                  disabled={disabled}
                  id="waitlist_button"
                >
                  Get Exclusive Updates
                </Button>
              </Form.Item>
            </Form>
          </WaitListForm>
        }
        {
          screenSize &&
          <MediaWrapper className="mobMedialWrapper">
            <MediaLinks className="mobMedialLinks">
              <a href="https://www.instagram.com/ai.fithub/?igshid=MmIzYWVlNDQ5Yg%3D%3D" target="_blank" without="true" rel="noopener noreferrer">
                <StaticImage src="../../images/instagram.webp" alt="Instagram" placeholder="none" />
                {/* <img src={Instagram} alt="Instagram" /> */}
              </a>
              <a href="https://www.facebook.com/profile.php?id=100093714846212&mibextid=LQQJ4d" target="_blank" without="true" rel="noopener noreferrer">
                <StaticImage src="../../images/facebook.webp" alt="Facebook" placeholder="none" />
                {/* <img src={Facebook} alt="Facebook" /> */}
              </a>
              <a href="https://twitter.com/aifithub?s=21" target="_blank" without="true" rel="noopener noreferrer">
                <StaticImage src="../../images/twitter.webp" alt="Twitter" placeholder="none" />
                {/* <img src={Twitter} alt="Twitter" /> */}
              </a>
              <a href="https://www.linkedin.com/company/aifithub/" target="_blank" without="true" rel="noopener noreferrer">
                <StaticImage src="../../images/linkedin.webp" alt="Linkedin" placeholder="none" />
              </a>
              {/* <a href="https://www.youtube.com/@spyncontent6734" target="_blank" without="true" rel="noopener noreferrer">
              <img src={Youtube} alt="Youtube" />
            </a>
            <a href="https://www.linkedin.com/company/spyn-corp/" target="_blank" without="true" rel="noopener noreferrer">
              <img src={Linkedin} alt="Linkedin" />
            </a> */}
            </MediaLinks>
            {/* <IntouchTerms>
            <Link to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link to="/tos">
              Terms of Service
            </Link>
          </IntouchTerms> */}
          </MediaWrapper>
        }
      </BannerFooter>
      {
        !screenSize &&
        <MediaWrapper className="mediawrapper">
          <MediaLinks className="medialinks">
            <a href="https://www.instagram.com/ai.fithub/?igshid=MmIzYWVlNDQ5Yg%3D%3D" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/instagram.webp" alt="Instagram" placeholder="none" />
              {/* <img src={Instagram} alt="Instagram" /> */}
            </a>
            <a href="https://www.facebook.com/profile.php?id=100093714846212&mibextid=LQQJ4d" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/facebook.webp" alt="Facebook" placeholder="none" />
              {/* <img src={Facebook} alt="Facebook" /> */}
            </a>
            <a href="https://twitter.com/aifithub?s=21" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/twitter.webp" alt="Twitter" placeholder="none" />
              {/* <img src={Twitter} alt="Twitter" /> */}
            </a>
            <a href="https://www.linkedin.com/company/aifithub/" target="_blank" without="true" rel="noopener noreferrer">
              <StaticImage src="../../images/linkedin.webp" alt="Linkedin" placeholder="none" />
            </a>
            {/* <a href="https://www.youtube.com/@spyncontent6734" target="_blank" without="true" rel="noopener noreferrer">
              <img src={Youtube} alt="Youtube" />
            </a>
            <a href="https://www.linkedin.com/company/spyn-corp/" target="_blank" without="true" rel="noopener noreferrer">
              <img src={Linkedin} alt="Linkedin" />
            </a> */}
          </MediaLinks>
          {/* <IntouchTerms>
            <Link to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link to="/tos">
              Terms of Service
            </Link>
          </IntouchTerms> */}
        </MediaWrapper>
      }
      <Modal title="" visible={openModal} onCancel={handleCancel} footer="" className="getappModal" width={screenSize ? '90vw' : '50vw'} centered maskClosable={false}>
        <EmailConfirm title="Welcome to the Fit Hub Family!" message="" from="exclusiveUpdate" handleCancel={handleCancel} />
      </Modal>
    </BannerSection>
  )
}

export default Banner
