import React, { Fragment, useEffect, useState } from "react"
import Banner from "../components/Banner"
import Contact from "../components/Contact"
import Journey from "../components/Journey"
import Metrics from "../components/Metrics"
import CustomerFeedback from "../components/CustomerFeedback/customer-feedback"
import Seo from "../components/seo"
import { StickyFooter } from "../components/StickyFooter"
import { WhatWeDo } from "../components/WhatWeDo"
import { isInViewport } from "../utils/inViewport"

const IndexPage = () => {
  const [showBg, setShowBg] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", changeBackground)
    return () => {
      window.removeEventListener("scroll", changeBackground)
    }
  }, [])

  const changeBackground = () => {
    if (document.getElementById("homeBanner")) {
      if (
        window.scrollY >= document.getElementById("homeBanner").clientHeight
      ) {
        setShowBg(true)
      } else {
        setShowBg(false)
      }
    }
    if (
      document.getElementById("journeyCard") &&
      isInViewport(document.getElementById("journeyCard"))
    ) {
      setShowBg(false)
    }
    if (
      document.getElementById("footerSec") &&
      isInViewport(document.getElementById("footerSec"))
    ) {
      setShowBg(false)
    }
  }

  return (
    <Fragment>
      <Banner />
      <Metrics />
      <WhatWeDo />
      <CustomerFeedback />
      <Contact />
      <Journey />
      {/* {
        showBg && <StickyFooter />
      } */}
    </Fragment>
  )
}

export default IndexPage

export const Head = () => <Seo title="Fithub" />
