import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Workouts from "../../images/workouts.webp";
import WorkoutCompleted from "../../images/workout-completed.webp";
import Meditations from "../../images/meditations.webp";
import MeditationsCompleted from "../../images/meditation-completed.webp";
import Countries from "../../images/countries.webp";
import { SectionContainer, MetricsList, MetricsListItem } from "./styles";

const data = [
  {
    "title": "Workouts generated",
    "key": "workouts_generated",
    "value": 5231
  },
  {
    "key": "minutes_of_workout_completed",
    "title": "Minutes of workout completed",
    "value": 128432
  },
  {
    "key": "meditations_generated",
    "title": "Meditations generated",
    "value": 3642
  },
  {
    "key": "minutes_of_meditation_completed",
    "title": "Minutes of meditation completed",
    "value": 98765
  },
  {
    "key": "number_of_countries",
    "title": "Number of countries",
    "value": 10
  },
]

const Metrics = () => {

  const [metricsData, setMetricsData] = useState(data)

  useEffect(() => {
    loadData();
    const intervalCall = setInterval(() => {
      loadData();
    }, 30000);
    return () => {
      clearInterval(intervalCall);
    };
  }, [])

  const loadData = async () => {
    try {
      const res = await fetch("https://api-main.aifithub.com/metrics");
      const responseData = await res.json();
      setMetricsData(responseData)
      // setMetricsData([...responseData, {
      //   "key": "countries",
      //   "title": "Countries present",
      //   "value": 10
      // }])
    } catch (e) {
    }
  }

  const getImages = (param) => {
    switch (param) {
      case "workouts_generated":
        return Workouts;
      case "meditations_generated":
        return Meditations;
      case "minutes_of_workout_completed":
        return WorkoutCompleted;
      case "minutes_of_meditation_completed":
        return MeditationsCompleted;
      case "number_of_countries":
        return Countries;
      default:
        return Workouts;
    }
  }

  return (
    <SectionContainer>
      <MetricsList>
        {
          metricsData.length > 0 && metricsData.map(dataItem =>
            <MetricsListItem key={dataItem.key}>
              <span className="imageWrapper">
                <img src={getImages(dataItem.key)} alt={dataItem.title} />
              </span>
              <h3>{dataItem.value}</h3>
              <h4>{dataItem.title}</h4>
            </MetricsListItem>
          )
        }
      </MetricsList>
    </SectionContainer>
  )
}

export default Metrics
